/* eslint-disable react/display-name */
import React, {useEffect, useRef} from 'react'
import {useRouter} from 'next/router'
import Link from 'next/link'

import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'
import {Container, media} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {palette} from 'styled-tools'
import classNames from 'classnames'

import {BreadCrumb} from '@festi/common/utils/categories'
import {fluidRange} from '@festi/common/utils/styles'
import settings from '@festi/common/constants/settings'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import {styledTheme} from '@festi/common/themes'

const newDesign = settings.redesign

interface Props {
  breadCrumbs: BreadCrumb[]
  disableIndex?: number
  hasBackButton?: boolean
}

type BreadCrumbProps = React.AnchorHTMLAttributes<HTMLAnchorElement>

const BreadCrumbItem = styled.a<BreadCrumbProps>`
  display: flex;
  align-items: center;
  ${fluidRange('font-size', '14px', '18px')}
  font-weight: 500;
  line-height: 1.1;
  color: ${palette('ui40Solid')};
  padding: 5px 8px 5px 0;
  transition: color 0.15s;

  .remixicon-icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    margin-right: 8px;
    color: ${palette('ui20Solid')};
  }

  &:first-child {
    .remixicon-icon {
      display: none;
    }
  }

  &:last-child {
    color: ${palette('blue')};
    pointer-events: none;
  }

  &:hover {
    color: ${palette('ui60Solid')};
  }

  ${media.md`
    padding: 8px 12px 8px 0;

    .remixicon-icon {
      margin-right: 12px;
    }
  `}

  &.newDesign {
    font-size: 14px;
    font-weight: 400;
    color: ${palette('blue')};

    .remixicon-icon {
      width: 14px;
      height: 14px;
      min-width: 14px;
      min-height: 14px;
    }
  }
`

const Bar = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  background-color: ${palette('white')};
  border-bottom: 1px solid ${palette('border')};
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`

const NewBar = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  background-color: transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`

const InnerBar = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;

  ${media.md`
    padding: 12px 0;
  `}
`

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: none;
  align-items: center;
  gap: 16px;
  padding-right: 16px;

  ${media.md`
    display: flex;
  `}
`

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  color: ${palette('blue')};
  background-color: transparent;
  border: none;
  cursor: ${styledTheme.cursor.pointer};
`

const Divider = styled.div`
  width: 1px;
  height: 12px;
  background-color: ${palette('ui10Solid')};
`

const BreadCrumbAnchor = React.forwardRef<HTMLAnchorElement, BreadCrumbProps>(
  ({children, ...props}: BreadCrumbProps, ref) => (
    <BreadCrumbItem {...props} ref={ref} className={classNames({newDesign})}>
      <ArrowRightSLineIcon />
      {children}
    </BreadCrumbItem>
  ),
)

export default function BreadCrumbsBar({
  breadCrumbs,
  disableIndex,
  hasBackButton,
}: Props): JSX.Element {
  const containerRef = useRef(null)
  const router = useRouter()

  useEffect(() => {
    // Starts a new Breadcrumb all the way over to the right
    const container = containerRef.current
    if (container) {
      container.scrollLeft = container.scrollWidth
    }
  }, [breadCrumbs])

  const CrumbBar = newDesign ? NewBar : Bar

  if (!breadCrumbs?.length) return null

  return (
    <CrumbBar ref={containerRef}>
      <Container>
        <InnerWrapper>
          {newDesign && hasBackButton && (
            <ButtonWrapper>
              <BackButton onClick={() => router.back()}>
                <ArrowLeftSLineIcon size={14} />
                <span>Til baka</span>
              </BackButton>
              <Divider />
            </ButtonWrapper>
          )}
          <InnerBar>
            {breadCrumbs.map(({path, label}: BreadCrumb, index) => {
              if (index === disableIndex) {
                return <BreadCrumbAnchor key={path}>{label}</BreadCrumbAnchor>
              } else {
                return (
                  <Link key={path} href={path} passHref legacyBehavior>
                    <BreadCrumbAnchor>{label}</BreadCrumbAnchor>
                  </Link>
                )
              }
            })}
          </InnerBar>
        </InnerWrapper>
      </Container>
    </CrumbBar>
  )
}
