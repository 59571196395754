import Link from 'next/link'
import HeartLineIcon from 'remixicon-react/HeartLineIcon'
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon'
import styled from 'styled-components'
import {palette} from 'styled-tools'

import {styledTheme} from '@festi/common/themes'
import {useUserPrices} from '@festi/common/utils/rest'
import {useWishlist} from '@festi/common/utils/wishlists'
import {DotsLoader, EmptyResults} from '@festi/common/components/common'
import {SideDrawer} from '@festi/common/components/layout'
import {fluidRange} from '@festi/common/utils/styles'

import NewWishlistItemCard from './NewWishlistItemCard'
import useHeaderHeight from 'src/components/layout/redesign/header/utilites/useHeaderHeight'

interface Props {
  isOpen?: boolean
  onRequestClose?: () => void
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const WishlistWrapper = styled.div`
  width: 100%;
`

const WishlistHeadWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 24px;
  ${fluidRange('padding-top', '20px', '24px')};
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-bottom', '20px', '24px')};
  ${fluidRange('padding-left', '16px', '40px')};
  border-bottom: 1px solid ${palette('ui10Solid')};
`

const WishlistHead = styled.div`
  display: flex;
  font-weight: 500;
  color: ${palette('blue')};
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-left', '16px', '40px')};
`

const WishlistButtons = styled.div`
  display: flex;
  gap: 4px;
`

const RemoveAllButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: auto;
  padding: 7px 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: ${palette('white')};
  color: ${palette('blue')};
  font-size: 16px;
  font-weight: 500;
  z-index: 1;
  cursor: ${styledTheme.cursor.pointer};

  &:hover {
    background-color: ${styledTheme.palette.ui5Solid};
    border: 1px solid ${styledTheme.palette.ui10Solid};
  }

  @media (max-width: 425px) {
    border-color: #d8dde6;
    background-color: transparent;
  }
`

const TextAnchor = styled(Link)`
  display: flex;
  align-items: center;
  color: ${palette('blue')};
  font-weight: 500;
  text-decoration: none;
  cursor: ${styledTheme.cursor.pointer};

  svg {
    margin-left: 8px;
  }
`

const WishlistProducts = styled.div`
  display: flex;
  flex-direction: column;
  ${fluidRange('gap', '8px', '12px')};
  ${fluidRange('padding-top', '24px', '40px')};
  ${fluidRange('padding-right', '16px', '40px')};
  ${fluidRange('padding-left', '16px', '40px')};
  ${fluidRange('padding-bottom', '24px', '40px')};
`

export default function NewWishlistDrawer({
  isOpen,
  onRequestClose,
}: Props): JSX.Element {
  const {headerHeight} = useHeaderHeight()
  const {wishlistItems, isValidating, removeAllFromWishlist} = useWishlist()

  const {data: userPrices, loading: userPriceLoading} = useUserPrices(
    wishlistItems?.map((w) => w.sku),
  )

  const len = wishlistItems?.length
  const isLoading = !wishlistItems && isValidating

  return (
    <SideDrawer
      top={headerHeight}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      noFocus={isLoading || !len}
      newDesign
    >
      {isLoading ? (
        <DotsLoader />
      ) : wishlistItems?.length === 0 ? (
        <EmptyResults icon={HeartLineIcon}>
          <span data-cy="wishlist-empty">Listinn er tómur</span>
        </EmptyResults>
      ) : (
        <ContentWrapper>
          <WishlistWrapper>
            <WishlistHeadWrapper>
              <WishlistHead>
                <span>Óskalistinn þinn</span>
              </WishlistHead>
            </WishlistHeadWrapper>
            <ButtonWrapper>
              <WishlistButtons>
                <RemoveAllButton
                  onClick={removeAllFromWishlist}
                  data-cy="remove-all-from-wishlist"
                >
                  Eyða öllu
                </RemoveAllButton>
              </WishlistButtons>

              <TextAnchor href="/oskalisti" onClick={onRequestClose}>
                <span>Sjá allar vörur</span>

                <ArrowRightLineIcon
                  size={20}
                  color={styledTheme.palette.lightBlue}
                />
              </TextAnchor>
            </ButtonWrapper>

            <WishlistProducts>
              {wishlistItems?.map((item) => (
                <NewWishlistItemCard
                  key={item.sku}
                  item={item}
                  userPrice={userPrices?.[item.sku]}
                  userPriceLoading={userPriceLoading}
                  onClick={onRequestClose}
                />
              ))}
            </WishlistProducts>
          </WishlistWrapper>
        </ContentWrapper>
      )}
    </SideDrawer>
  )
}
