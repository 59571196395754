import {useState} from 'react'

import Link from 'next/link'
import {useLocalStorage, useMedia} from 'react-use'
import classNames from 'classnames'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import TimeLineIcon from 'remixicon-react/TimeLineIcon'
import MapPinLineIcon from 'remixicon-react/MapPinLineIcon'
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon'
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon'

import {H4, H5} from '@festi/common/components/typography'
import {MainButton} from '@festi/common/components/buttons'
import {styledTheme} from '@festi/common/themes'
import {CustomPopover} from '@festi/common/components/common'

import UtilityButton from './UtilityButton'

interface Store {
  name: string
  phone: string
  address: string
  openingHours: {
    weekday: string
    saturday: string
    sunday: string
  }
}
const stores: Store[] = [
  {
    name: 'Lindir',
    phone: '544-4000',
    address: 'Skógarlind 2, 201 Kópavogur',
    openingHours: {
      weekday: '10:00 - 18:00',
      saturday: '10:00 - 16:00',
      sunday: '12: 00 - 18:00',
    },
  },
  {
    name: 'Skeifan',
    phone: '544-4000',
    address: 'Skeifan 19, 108 Reykjavík',
    openingHours: {
      weekday: '10:00 - 18:00',
      saturday: '10:00 - 16:00',
      sunday: '12: 00 - 18:00',
    },
  },
  {
    name: 'Grandi',
    phone: '544-4000',
    address: 'Fiskislóð 15, 101 Reykjavík',
    openingHours: {
      weekday: '10:00 - 18:00',
      saturday: '10:00 - 16:00',
      sunday: '12: 00 - 18:00',
    },
  },
  {
    name: 'Akureyri',
    phone: '544-4000',
    address: 'Tryggvabraut 18, 600 Akureyri',
    openingHours: {
      weekday: '10:00 - 18:00',
      saturday: '10:00 - 16:00',
      sunday: '12: 00 - 18:00',
    },
  },
]

const Container = styled.div`
  display: flex;
  width: 360px;
  max-width: 360px;

  ${media.md`
    width: 760px;
    max-width: 760px;
  `}
`

const Half = styled.div`
  flex: 1;
  padding: 32px 24px;

  ${media.md`
    padding: 32px 0 32px 32px;

    &:last-child {
      padding-right: 32px;
    }
  `}
`

const Line = styled.div`
  display: none;
  width: 1px;
  background-color: ${palette('border')};

  ${media.md`
    display: block;
  `}
`

const StoreItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;
  margin-bottom: 2px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;

  .remixicon-icon {
    fill: ${palette('ui30Solid')};
  }

  circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${palette('green')};
  }

  &.selected,
  &:hover {
    background-color: ${palette('ui4Solid')};

    .remixicon-icon {
      fill: ${palette('blue')};
    }
  }

  ${media.md`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}
`

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Row = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;

  .remixicon-icon {
    fill: ${palette('lightBlue')};
  }

  a {
    font-size: 1rem;
    font-weight: 100;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  color: #4d5686;
`

const OpenNow = styled.div`
  font-size: 1.125rem;
  width: fit-content;
  padding: 4px 8px;
  margin-bottom: 6px;
  color: ${palette('white')};
  background-color: ${palette('green')};
  border-radius: 8px;
`

const TimeGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
`

const DetailHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const Back = styled.div`
  width: 36px;
  height: 36px;
  cursor: pointer;

  ${media.md`
    display: none;
  `}
`

interface StoreHoursProps {
  handleClose: () => void
}

function StoreHours({handleClose}: StoreHoursProps) {
  const isMobile = useMedia(
    `(max-width: ${styledTheme.screenSize.sizeMD})`,
    false,
  )

  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useLocalStorage(
    'storeHoursPopover',
    stores[0],
  )

  const mobileOpen = isMobile && open
  const mobileClosed = isMobile && !open

  const handleSelect = (store: Store) => {
    setOpen(true)
    setSelected(store)
  }

  return (
    <Container>
      <Half
        style={{
          display: mobileOpen ? 'none' : 'block',
        }}
      >
        <H4 headline withGutter>
          Allar verslanir
        </H4>

        <div>
          {stores.map((store) => (
            <StoreItem
              key={store.name}
              className={classNames({
                selected: !isMobile && store.name === selected?.name,
              })}
              onClick={() => handleSelect(store)}
            >
              <Item>
                <div>
                  <circle />
                </div>
                <H5>{store.name}</H5>
              </Item>

              <ArrowRightSLineIcon />
            </StoreItem>
          ))}
        </div>

        <Link href="/opnunartimar" onClick={handleClose}>
          <div style={{width: 'fit-content', marginTop: 32}}>
            <MainButton
              label="Sjá allar upplýsingar"
              buttonVariant="ghost"
              size="small"
            />
          </div>
        </Link>
      </Half>

      <Line />

      <Half
        style={{
          display: mobileClosed ? 'none' : 'block',
        }}
      >
        <DetailHeader>
          <Back onClick={() => setOpen(false)}>
            <ArrowLeftSLineIcon size={16} />
          </Back>

          <H4 headline withGutter>
            {selected?.name}
          </H4>
        </DetailHeader>

        <Row>
          <div>
            <MapPinLineIcon size={24} />
          </div>

          <Column>
            <Link
              href={`https://maps.google.com/?q=${selected?.address}`}
              target="_blank"
            >
              {selected?.address}
            </Link>
            <div>
              <span>Sími: </span>
              <Link href={`tel:${selected?.phone}`}>{selected?.phone}</Link>
            </div>
          </Column>
        </Row>

        <Row>
          <div>
            <TimeLineIcon size={24} />
          </div>

          <Column>
            <OpenNow>{'Opið núna'}</OpenNow>

            <Column>
              <TimeGrid>
                <span>Mánudaga til föstudaga:</span>
                <span>{selected?.openingHours.weekday}</span>
              </TimeGrid>

              <TimeGrid>
                <span>Laugardaga:</span>
                <span>{selected?.openingHours.saturday}</span>
              </TimeGrid>

              <TimeGrid>
                <span>Sunnudaga:</span>
                <span>{selected?.openingHours.sunday}</span>
              </TimeGrid>
            </Column>
          </Column>
        </Row>
      </Half>
    </Container>
  )
}

export default function StoreButton() {
  return (
    <CustomPopover
      trigger={<UtilityButton icon={MapPinLineIcon} />}
      content={<StoreHours handleClose={() => console.log('close')} />}
    />
  )
}
