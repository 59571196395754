import classNames from 'classnames'
import {palette} from 'styled-tools'
import styled from 'styled-components'
import {RemixiconReactIconComponentType} from 'remixicon-react'

import {P} from '@festi/common/components/typography'

const Button = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  gap: 4px;
  padding: 12px;
  border: 1px solid transparent;
  background: none;
  border-radius: 8px;
  white-space: nowrap;
  cursor: pointer;

  .remixicon-icon {
    fill: ${palette('blue')};
  }

  &.selected,
  &:hover {
    background: ${palette('ui5Solid')};
    border: 1px solid ${palette('grey')};
  }

  &.selected {
    .remixicon-icon {
      fill: ${palette('ui40Solid')};
    }
  }
`

const Circle = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  height: 18px;
  padding-left: 5px;
  padding-right: 3px;
  font-size: 0.75rem;
  font-weight: 500;
  border-radius: 50%;
  background-color: ${palette('pink')};
`

interface Props {
  icon: RemixiconReactIconComponentType
  title?: string
  counter?: number
  selected?: boolean
  onClick?: () => void
}

export default function UtilityButton({
  title,
  icon: Icon,
  counter,
  selected,
  onClick,
}: Props) {
  return (
    <Button className={classNames({selected})} onClick={onClick}>
      <div>
        <Icon size={24} />
      </div>

      {!!title && <P bold>{title}</P>}

      {!!counter && <Circle>{counter}</Circle>}
    </Button>
  )
}
