import React, {useCallback, useState} from 'react'

import {useUpdateEffect} from 'react-use'
import styled from 'styled-components'
import {media} from 'styled-bootstrap-grid'
import MenuLineIcon from 'remixicon-react/MenuLineIcon'

import {P} from '@festi/common/components/typography'
import {useCommonContext} from '@festi/common/contexts/common'

import CategoryMenu from '../menu'

const Wrapper = styled.div`
  ${media.md`
    min-width: 95px;
  `}
`

const Click = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  ${P} {
    display: none;
  }

  ${media.md`
    ${P} {
      display: block;
    }
  `}
`

const MenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  pointer-events: none;
`

export default function HeaderMenu() {
  const {activeNavItem} = useCommonContext()

  const [open, setOpen] = useState(false)
  const [lastOpenNav, setLastOpenNav] = useState<string | null>(null)

  const handleClick = useCallback((state: boolean) => {
    setOpen(state)

    if (state) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [])

  useUpdateEffect(() => {
    if (activeNavItem !== lastOpenNav) {
      handleClick(true)
      setLastOpenNav(activeNavItem)
    }
  }, [activeNavItem])

  return (
    <Wrapper>
      <Click onClick={() => handleClick(!open)}>
        <div>
          <MenuLineIcon size={24} />
        </div>
        <P bold>Vörur</P>
      </Click>

      <MenuContainer>
        <CategoryMenu
          open={open}
          openFromFooter={activeNavItem !== lastOpenNav}
          handleClose={() => handleClick(false)}
        />
      </MenuContainer>
    </Wrapper>
  )
}
